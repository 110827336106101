import type { PaginateResult } from "mongoose"

export enum MutationType {
  CREATE = "CREATE",
  DELETE = "DELETE",
  UPDATE = "UPDATE",
  // SUPER USER ONLY
  AUDIT = "AUDIT", // Can be used to track activity. Ex: user x opens Asset x
  LOGIN = "LOGIN",
  LOGIN_API = "LOGIN_API",
  LOGIN_ORGANIZATION = "LOGIN_ORGANIZATION",
}

export interface MutationLog {
  _id: string
  type: MutationType
  organization?: string
  user?: { _id?: string; description: string }
  userOrganization?: { _id?: string; description: string }
  documentSchema?: string
  reference?: unknown
  // subReference
  description?: string
  prop?: string
  original?: string
  updated?: string
  createdAt: string
  asset?: string
  transactionId?: string
}

export const useMutationLogStore = defineStore("mutation-logs", () => {
  /** @deprecated */
  const mutationLogs = ref<Array<MutationLog>>([])

  const getMutationLogs = async (queryParameters?: Record<string, unknown>): Promise<Array<MutationLog>> => {
    return useCrudMethods("/mutation-logs", mutationLogs).readItems({ ...queryParameters, populate: "reference" })
  }

  const getMutationLogsByPage = async (queryParameters: Record<string, unknown>) => {
    const query = getUrlSearchParams(queryParameters)

    const { data } = await useSamApi<PaginateResult<MutationLog>>(`/mutation-logs?${query}`)
    return data
  }

  return {
    getMutationLogs,
    getMutationLogsByPage
  }
})
